.welcome-message {
  p.header {
    @include app-text-style(1.688rem, 1.52, -1px, 600);
    text-align: center;
    color: $charcoal-grey;
    margin: 0;
  }
}

.loading-animation {
  &.local {
    position: relative;
  }

  &:not(.local) {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 10000;

    .logo-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100vw;
      height: 100vh;
      text-align: center;
      vertical-align: middle;
    }

    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      margin-top: -15px;
      margin-left: -15px;
    }
  }
}

.section-name {
  font-weight: 300;
  display: inline-block;
  margin-bottom: 15px;
  color: #667080;

  [class^="icon-"],
  [class^=" icon-"],
  .section-title {
    font-size: 16px;
    font-weight: 700;

    &:before {
      margin-right: 3px;
    }
  }

  p {
    margin-top: 0px;
  }
}

.copyright {
  margin: 0px;

  p {
    @include app-text-style(0.625rem, 1.4, 1px, 400);
    margin: 0px;
    color: $dark-petrol-grey;
  }
}

.gears {
  background: url("../../assets/img/gears@2x.png") no-repeat center center fixed;
}

.profile-pic {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.btr-logo {
  position: fixed;
  right: 0;
  bottom: 30px;
  left: 0;
  text-align: center;

  img {
    -webkit-filter: grayscale(100%);
  }
}

.user-photo {
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.section-container {
  padding: 18px;
  background-color: white;
}

.center-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.center-fixed-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 959px) {
  .section-name {
    padding-left: 25px !important;
  }
}

.toast-error {
  background-color: #D92121;
}

.toast-close-button {
  top: 0px;
  font-size: 25px;
  right: 5px;
}

.highlighted,
.highlighted span {
  background-color: $review-highlight;
  color: #000000;
  cursor: pointer;

  &:hover {
    box-shadow: inset 0 0 0 1px $review-hover;
  }
}

.highlighted.highlight-selected {
  &.pending-comment {
    background-color: $review-highlight-selected;

    span {
      background-color: $review-highlight-selected;
    }
  }

  &.posted-comment {
    background-color: $review-comment-selected;

    span {
      background-color: $review-comment-selected;
    }
  }
}

.highlighted.posted-comment {
  background-color: $review-comment;
}

.highlighted.highlight-selected,
.highlighted.posted-comment {
  * {
    background: none !important;
    background-color: none !important;
  }
}

.action-info-button {
  .mat-mdc-button-wrapper {
    height: 100%;
    width: 100%;
  }
}

header.section-header {
  margin-bottom: 24px;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    color: $dark-black;
  }
}

#chartjs-tooltip {
  z-index: 9999;
  position: absolute;
  pointer-events: none;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba($color: $gray-30, $alpha: 1);
  color: $white;
  display: flex;
  gap: 10px;
  font-size: 12px;

  .data-point {
    display: flex;
    flex-direction: column;
  }

  &.data-points-greater-than-3 {
    flex-wrap: wrap;
    max-width: 500px;
  }
}